import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { XappOnboarderService } from '../services/xapp-onboarder/xapp-onboarder.service';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { NotificationService } from '../services/ui/notification.service';

@Component({
  selector: 'rd-simulator',
  templateUrl: './simulator.component.html',
  styleUrls: ['./simulator.component.scss']
})
export class SimulatorComponent implements OnInit {
  @ViewChild('f') myNgForm;
  form: FormGroup;
  features = [
    { feature: 'ES', enableE2Sim: false },
    { feature: 'TS', enableE2Sim: false },
    { feature: 'QOS', enableE2Sim: false }
  ];

  simData: { feature: string, enableE2Sim: boolean };;

  constructor(
    private fb: FormBuilder,
    private xappOnboarderService: XappOnboarderService,
    private notificationService: NotificationService,
  ) { }

  ngOnInit(): void {
    const controls = {};
    this.features.forEach(feature => {
      controls[feature.feature] = [feature.enableE2Sim];
    });

    this.form = this.fb.group(controls);
    this.getContainerData()
  }

  getContainerData() {
    this.xappOnboarderService.getContainerStatus().subscribe({
      next: (res: any) => {
        let data = JSON.parse(res);
        let Original = Object.entries(data);
        let mainArray2: any = [];
        for (let i = 0; i < Original.length; i++) {
          mainArray2.push(Original[i][1])
        }
        const newData = mainArray2.flat();
        newData.map(x => {
          if (x.container_name == "r4-e2simulator-es-577556c87-7jnth") {
            this.form.get("ES")?.setValue(true);
          }
          if (x.container_name == "r4-e2simulator-ts-68ff976d65-kwv2x") {
            this.form.get("TS")?.setValue(true);
          }
          if (x.container_name == "r4-e2simulator-qos-9cd66b497-bm9fg") {
            this.form.get("QOS")?.setValue(true);
          }
        });
      },
      error: console.log,
    })
  }

  // This function will be called when a toggle is switched
  onToggleChange(featureName: string, event: any) {
    const enabled = event.target.checked;  // true or false
    this.simData = {
      feature: featureName,
      enableE2Sim: enabled
    };
    console.log('Data to send:', this.simData);  // For testing, replace this with your API call
  }

  startSimulator() {
    if (this.simData) {
      this.xappOnboarderService.startSimulator(this.simData)
        .subscribe({
          next: (response: string) => {
            this.notificationService.success('Simulator started successfully!');
            this.myNgForm.resetForm();
            console.log('Success:', response);
          },
          error: (error) => {
            this.notificationService.warn("Simulator has failed");
            this.myNgForm.resetForm();
            console.error('Error:', error);
          }
        }
        );
    }
  }

}
