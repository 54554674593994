<div class="commonSection">{{header}}
  <h5 class="subheading border-bottom pb-2" style="font-weight: bold;">GNB Information</h5>
  <div class="row">
    <div class="col-md-8">
      <div class="infoBlk">
        <div class="grid2">
          <div class="label">Inventory Name</div>
          <div class="value">{{inventoryName}}
          </div>
        </div>
        <div class="grid2">
          <div class="label">Connection Status</div>
          <div class="value">{{ connectionStatus}}</div>
        </div>
        <div class="grid2">
          <div class="label">Nb Id</div>
          <div class="value">{{ nbId }}</div>
        </div>
        <div class="grid2">
          <div class="label">Plmn Id</div>
          <div class="value">{{ plmnId }}</div>
        </div>
        <div class="grid2">
          <div class="label">Health Check Timestamp Sent</div>
          <div class="value">{{ healthCheckTimestampSent }}</div>
        </div>
      </div>
    </div>

    <div class="col-md-4">
      <div class="imgBlk">
        <img src="assets/images/CUDU.jpeg" alt="GNB Image" height="130" width="150" />
      </div>
    </div>
  </div>
</div>

<div class="commonSection mt-4">
  <div class="d-flex justify-content-between">
    <h5 class="subheading" style="font-weight: bold;">Ran Function</h5>
    <ul class="nav justify-content-end mainTabs">
      <li class="nav-item" *ngFor="let category of ['kpm', 'rc', 'ccc']">
        <a class="nav-link" [class.active]="selectedCategory === category" (click)="selectCategory(category)">
          {{ category.toUpperCase()}}
        </a>
      </li>
    </ul>
  </div>
  <div class="content-blk">
    <ul class="nav justify-content-end subchild mt-2 mb-4">
      <li class="nav-item">
        <a class="nav-link" [class.active]="selectedTab === 'general'" (click)="selectTab('general')">General Info</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" [class.active]="selectedTab === 'measurement'"
          (click)="selectTab('measurement')">Measurement</a>
      </li>
    </ul>

    <div class="data-blk" *ngIf="selectedTab === 'general'">
      <div class="infoBlk" *ngIf="selectedCategory === 'kpm' && inventory?.kpm">
        <!-- KPM General Info Content -->
        <div class="grid2">
          <div class="label">Ran Func ID:</div>
          <div class="value">{{ inventory.kpm.ranFuncID }}</div>
          <div class="label">Ran Func OID:</div>
          <div class="value">{{ inventory.kpm.ranFuncOID }}</div>
          <div class="label">Ran Function Name:</div>
          <div class="value">{{ inventory.kpm.ranFunctionName }}</div>
          <!-- Add other general info fields as needed -->
        </div>
      </div>
      <div class="infoBlk" *ngIf="selectedCategory === 'rc' && inventory?.rc">
        <!-- RC General Info Content -->
        <div class="grid2">
          <div class="label">Ran Func ID:</div>
          <div class="value">{{ inventory.rc.ranFuncID }}</div>
          <div class="label">Ran Func OID:</div>
          <div class="value">{{ inventory.rc.ranFuncOID }}</div>
          <div class="label">Ran Function Name:</div>
          <div class="value">{{ inventory.rc.ranFunctionName }}</div>
          <!-- Add other general info fields as needed -->
        </div>
      </div>
      <div class="infoBlk heightScroll " *ngIf="selectedCategory === 'ccc' && inventory?.ccc">
        <!-- CCC General Info Content -->
        <div class="grid2">
          <div class="label">Ran Func ID:</div>
          <div class="value">{{ inventory.ccc.ranFuncID }}</div>
          <div class="label">Ran Func OID:</div>
          <div class="value">{{ inventory.ccc.ranFuncOID }}</div>
          <div class="label">Ran Function Name:</div>
          <div class="value">{{ inventory.ccc.ranFunctionName }}</div>
          <!-- Add other general info fields as needed -->
        </div>
      </div>
    </div>

    <div *ngIf="selectedTab === 'measurement'">
      <div class="heightScroll" *ngIf="selectedCategory === 'kpm' ">
        <!-- KPM Measurement Content -->
        <table class="app-control-table width-100 tableRan">
          <tr>
            <th class="thRan">Measurement Name</th>
            <th class="thRan">Action</th>
          </tr>
          <tr *ngFor="let key of kpmData">
            <td class="tdRan">{{key.measName}}</td>
            <td class="tdRan">
              <div class="custom-radio">
                <label class="switch">
                  <input type="checkbox" [(ngModel)]="key.active">
                  <span class="slider round"></span>
                </label>
              </div>
            </td>
          </tr>
        </table>
        <div class="noDataText" *ngIf="!kpmData">No data found</div>
      </div>
      <div class="mt-3 text-right">
        <button class="meas_submit" *ngIf="selectedCategory === 'kpm'" type="submit" (click)="updateToggleValue(kpmData , selectedCategory)">Submit</button>
      </div>
      <div class="heightScroll" *ngIf="selectedCategory === 'rc'">
        <!-- RC Measurement Content -->
        <table class="app-control-table width-100 tableRan">
          <tr>
            <th class="thRan">Measurement Name</th>
            <th class="thRan">Action</th>
          </tr>
          <tr *ngFor="let key of rcData">
            <td class="tdRan">{{key.measName}}</td>
            <td class="tdRan">
              <div class="custom-radio">
                <label class="switch">
                  <input type="checkbox" [(ngModel)]="key.active">
                  <span class="slider round"></span>
                </label>
              </div>
            </td>
          </tr>
        </table>
        <div class="noDataText" *ngIf="!rcData">No data found</div>
      </div>
      <div class="mt-3 text-right">
        <button class="meas_submit" *ngIf="selectedCategory === 'rc'" type="submit" (click)="updateToggleValue(rcData , selectedCategory)">Submit</button>
      </div>
      <div class="heightScroll" *ngIf="selectedCategory === 'ccc'">
        <!-- CCC Measurement Content -->
        <table class="app-control-table width-100 tableRan">
          <tr>
            <th class="thRan">Measurement Name</th>
            <th class="thRan">Action</th>
          </tr>
          <tr *ngFor="let key of cccData">
            <td class="tdRan">{{key.measName}}</td>
            <td class="tdRan">
              <div class="custom-radio">
                <label class="switch">
                  <input type="checkbox" [(ngModel)]="key.active">
                  <span class="slider round"></span>
                </label>
              </div>
            </td>
          </tr>
        </table>
        <div class="noDataText" *ngIf="!cccData">No data found</div>
      </div>
      <div class="mt-3 text-right">
        <button class="meas_submit" *ngIf="selectedCategory === 'ccc'" type="submit" (click)="updateToggleValue(cccData , selectedCategory)">Submit</button>
      </div>
    </div>
  </div>
</div>

<div class="commonSection mt-4">
  <h5 class="subheading border-bottom pb-2" style="font-weight: bold;">KPIs stats</h5>

  <table mat-table [dataSource]="dataSource1" class="mat-elevation-z8">
    <!-- CellID Column -->
    <ng-container matColumnDef="Cell-ID">
      <th mat-header-cell *matHeaderCellDef> Cell-ID </th>
      <td mat-cell *matCellDef="let element"> {{element.CellID}} </td>
    </ng-container>

    <!-- Prbusage Column -->
    <ng-container matColumnDef="Prbusage">
      <th mat-header-cell *matHeaderCellDef> Prbusage </th>
      <td mat-cell *matCellDef="let element"> {{element.Prbusage}} </td>
    </ng-container>

    <!-- pdcpUL Column -->
    <ng-container matColumnDef="pdcpUL">
      <th mat-header-cell *matHeaderCellDef> PdcpUL </th>
      <td mat-cell *matCellDef="let element"> {{element.pdcpUL}} </td>
    </ng-container>

    <!-- pdcpDL Column -->
    <ng-container matColumnDef="pdcpDL">
      <th mat-header-cell *matHeaderCellDef> PdcpDL </th>
      <td mat-cell *matCellDef="let element"> {{element.pdcpDL}} </td>
    </ng-container>

    <!-- connMean Column -->
    <ng-container matColumnDef="connMean">
      <th mat-header-cell *matHeaderCellDef> ConnMean </th>
      <td mat-cell *matCellDef="let element"> {{element.connMean}} </td>
    </ng-container>

    <!-- connMax Column -->
    <ng-container matColumnDef="connMax">
      <th mat-header-cell *matHeaderCellDef> ConnMax </th>
      <td mat-cell *matCellDef="let element"> {{element.connMax}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</div>