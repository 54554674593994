<div class="d-grid grid75_25">
  <div>
    <div class="grid3 d-grid">
      <!-- Gnb Status D3 Chart -->
      <div class="common-blk">
        <div class="d-grid headerblk">
          <h6>Gnb Status</h6>
          <div class="count">{{coordinatesData}}</div>
        </div>
        <div #Gnb class="contentBlk"></div>
        <div class="d-grid status mf-15 ml-72">
          <div class="online d-grid">
            Connected : <span>{{connected}}</span>
          </div>
          <div class="offline d-grid">
            Disconnected : <span>{{disConnected}}</span>
          </div>
        </div>
      </div>
      <!-- Xapps Status D3 Chart -->
      <div class="common-blk">
        <div class="d-grid headerblk">
          <h6>Xapps Status</h6>
          <div class="count">{{xappsTotalData}}</div>
        </div>
        <div #Xapps class="contentBlk"></div>
        <div class="d-grid status mf-15 ml-72">
          <div class="online d-grid">
            Running : <span>{{xappsRunning}}</span>
          </div>
          <div class="offline d-grid">
            Failed : <span>{{xappsFailed}}</span>
          </div>
        </div>
      </div>
      <!-- RIC Services D3 Chart -->
      <div class="common-blk">
        <div class="d-grid headerblk">
          <h6>RIC Services</h6>
          <div class="count">{{allContainerData}}</div>
        </div>
        <div #Container class="contentBlk"></div>
        <div class="d-grid status mf-15 ml-68">
          <div class="online d-grid">
            Running : <span>{{containerRunning}}</span>
          </div>
          <div class="offline d-grid">
            Failed : <span>{{containerFailed}}</span>
          </div>
          <div class="pending d-grid">
            Pending : <span>{{containerPending}}</span>
          </div>
        
        </div>
      </div>
    </div>

    <div class="grid3 d-grid">
      <!-- Cell Count -->
      <div class="common-blk">
        <div class="d-grid headerblk">
          <h6>Cell Count</h6>
        </div>
        <div class="contentBlk">
          <div class="matCardAlign" *ngIf="cellCount">{{cellCount}}</div>
          <div class="matCardAlign" *ngIf="!cellCount">0</div>
          <div class="imgblk">
            <img src="assets/images/radio-tower.svg" alt="radio-tower" />
          </div>
          <div class="text"></div>
        </div>
      </div>

      <!-- UE Count -->
      <div class="common-blk">
        <div class="d-grid headerblk">
          <h6>UE Count</h6>
        </div>
        <div class="contentBlk">
          <div class="matCardAlign" *ngIf="ueCount">{{ueCount}}</div>
          <div class="matCardAlign" *ngIf="!ueCount">0</div>
          <div class="imgblk">
            <img src="assets/images/phone_wifi.svg" alt="phone_wifi" style="height: 71px;" />
          </div>
          <div class="text"></div>
        </div>
      </div>

      <!-- Subscription count -->
      <div class="common-blk">
        <div class="d-grid headerblk">
          <h6>Subscription</h6>
        </div>
        <div class="contentBlk">
          <div class="matCardAlign" *ngIf="subscriptionCount">{{subscriptionCount}}</div>
          <div class="matCardAlign" *ngIf="!subscriptionCount">0</div>

          <div class="imgblk">
            <img src="assets/images/subscribe.svg" alt="phone_wifi" style="height: 71px;" />
          </div>
          <!-- <div class="text">Last 15 minutes</div> -->

          <div class="text">.</div>
        </div>
      </div>
    </div>

    <!-- Energy Saving Status D3 Chart -->
    <div class="grid d-grid mt-15">
      <div class="common-blk">
        <div class="d-grid headerblk">
          <h6>Energy Saving Status</h6>
          <div class="count"></div>
        </div>
        <div class="contentBlk">
          <div id="lineChart"></div>
          <div class="d-grid status ml-72">
            <div class="online d-grid">
              Energy saving ON : <span>0</span>
            </div>
            <div class="offline d-grid">
              Energy saving OFF : <span>0</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Grafana -->
  <div class="grid1 ">
    <div class="">
      <div class="d-grid headerblk box-shadow">
        <h6>Ric Infra Status</h6>
        <div class="count"></div>
      </div>
      <span class="grafanapics"><iframe *ngIf="iFrame2" [src]="iFrame2" title="Memory used" height="206"
          style="border:0; margin-bottom: 12px; height: 238px;"></iframe>
      </span>
      <span class="backend"><iframe *ngIf="iFrame3" [src]="iFrame3" title="CPU Used" height="206"
          style="border:0;margin-bottom: 12px"></iframe>
      </span>
      <span class="cpu_average_time"><iframe *ngIf="iFrame4" [src]="iFrame4" title="CPU Usage" height="210"
          style="border:0"></iframe>
      </span>
    </div>
  </div>

  <div class="tabgrid" style="display: none;">
    <div class="tabgrid-col">
      <div class="title">
        <!-- {{ selectedDetail.ranFuncDescription }} (Ransum Def.: 001.001.001) -->
      </div>
    </div>
  </div>
