
<div class="app-control__section">
  <div class="header">
    <h3>UE List</h3>
    <div class="right-action">
      <button mat-mini-fab (click)="refreshPage()">
        <span class="material-symbols-outlined"> refresh </span>
      </button>
    </div>
  </div>

  <table
    mat-table
    [dataSource]="dataSource"
    matSort
    class="catalog-table"
    class="catalog_sect"
    matSortActive="plmn_id"
    matSortDirection="asc"
  >
    <!-- connection_status  -->
    <ng-container matColumnDef="connection_status">
      <mat-header-cell *matHeaderCellDef> Ue ID </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{ element.ue_id }} </mat-cell>
    </ng-container>

    <!-- plmn_id-->
    <ng-container matColumnDef="plmn_id">
      <mat-header-cell *matHeaderCellDef> RSRP </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{ element.data.rsrp }} </mat-cell>
    </ng-container>

    <!-- nb_id  -->
    <ng-container matColumnDef="nbid">
      <mat-header-cell *matHeaderCellDef> RSRQ</mat-header-cell>
      <mat-cell *matCellDef="let element"> {{ element.data.rsrq }} </mat-cell>
    </ng-container>

    <!--Inventory_name -->
    <ng-container matColumnDef="Inventory_name">
      <mat-header-cell *matHeaderCellDef> RSSINR</mat-header-cell>
      <mat-cell *matCellDef="let element"> {{ element.data.rssinr }} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="nrcellIdentity">
      <mat-header-cell *matHeaderCellDef> Serving cell</mat-header-cell>
      <mat-cell *matCellDef="let element">
        {{ element.data.nrcellIdentity }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="noRecordsFound">
      <mat-footer-cell *matFooterCellDef></mat-footer-cell>
    </ng-container>

    <mat-header-row
      *matHeaderRowDef="displayedColumns; sticky: true"
    ></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
  </table>
</div>
