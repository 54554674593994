import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs-compat';
import { LoadingDialogService } from '../services/ui/loading-dialog.service';
import { MatDialog } from '@angular/material/dialog';
import { NotificationService } from '../services/ui/notification.service';
import { InstanceSelectorService } from '../services/instance-selector/instance-selector.service';
import { XappOnboarderService } from '../services/xapp-onboarder/xapp-onboarder.service';
import { UiService } from '../services/ui/ui.service';
import { interval } from 'rxjs';
import { RicInstance } from '../interfaces/dashboard.types';

@Component({
  selector: 'rd-ue',
  templateUrl: './ue.component.html',
  styleUrls: ['./ue.component.scss']
})
export class UeComponent implements OnInit {
  refreshPage(): void {
    // Reload the entire page
    window.location.reload();
  }
  public updateSubscription: Subscription | undefined;
  dataSubject: any = {}; 
  darkMode: boolean;
  panelClass: string;
  displayedColumns: string[] = ['connection_status', 'plmn_id', 'nbid','Inventory_name','nrcellIdentity'];
  public instanceChange: Subscription;
  private instanceKey: string;
  dataSource!: MatTableDataSource<any>;


  @ViewChild(MatSort)set matSort(sort:MatSort){
    this.dataSource.sort=sort;
  };
  items: any;

  constructor(
    private changeDetectorRef :ChangeDetectorRef,
    private loadingDialogService: LoadingDialogService,
    private dialog: MatDialog,
    private notificationService: NotificationService,
    public instanceSelectorService: InstanceSelectorService,
    private xappOnboarderService: XappOnboarderService,
    public ui: UiService,
    
) { }


  ngOnInit() {
    this.xappOnboarderService.Refreshrequired.subscribe(Response=>{
      this.getUe();
      this.updateSubscription = interval(1000).subscribe(() => {
        this.getUe();
      });
      
    })

   
    
    this.getUe();
    this.ui.darkModeState.subscribe((isDark) => {
      this.darkMode = isDark;
    });

    this.instanceChange = this.instanceSelectorService.getSelectedInstance().subscribe((instance: RicInstance) => {
      if (instance.key) {
        this.instanceKey = instance.key;
      }
    });
 }

  ngOnDestroy() {
    this.instanceChange.unsubscribe();
       // Unsubscribe when the component is destroyed
       if (this.updateSubscription) {
        this.updateSubscription.unsubscribe();
      }
  } 

  /*----- **
   Function : getUe()
   Description : This function is to determine the UE chart list on UE screen 
    -------**/

    getUe(){
    this.xappOnboarderService.getUe().subscribe({
      next: (res) => {
        
        let data = JSON.parse(res);
        let Original = Object.entries(data);
        let mainArray: any = [];

        for (let i = 0; i < Original.length; i++) {
          let a = {"data":Original[i][1],"ue_id":Original[i][0]}
          console.log(a,'a');
          mainArray.push(a)

        }
        const newData = mainArray.flat();
        this.dataSource = new MatTableDataSource(newData);
        // this.dataSource.sort = this.sort;
        this.changeDetectorRef.detectChanges();
      },
      error: console.log,

    })
    
  }


 
}