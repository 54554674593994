<!--
  ========================LICENSE_START=================================
  O-RAN-SC
  %%
  Copyright (C) 2019 AT&T Intellectual Property
  %%
  Licensed under the Apache License, Version 2.0 (the "License");
  you may not use this file except in compliance with the License.
  You may obtain a copy of the License at

       http://www.apache.org/licenses/LICENSE-2.0

  Unless required by applicable law or agreed to in writing, software
  distributed under the License is distributed on an "AS IS" BASIS,
  WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
  See the License for the specific language governing permissions and
  limitations under the License.
  ========================LICENSE_END===================================
  -->
<div class="app-control__section">
  <div class="header">
    <h3>GNB List</h3>
    <div class="right-action">
      <button mat-mini-fab (click)="refreshPage()">
        <span class="material-symbols-outlined"> refresh </span>
      </button>
    </div>
  </div>
  <table mat-table [dataSource]="dataSource" matSort class="catalog-table" class="catalog_sect" matSortActive="plmn_id"
    matSortDirection="asc">
    <!-- connection_status  -->
    <ng-container matColumnDef="connection_status">
      <mat-header-cell *matHeaderCellDef> Connection Status </mat-header-cell>
      <mat-cell *matCellDef="let element" [ngClass]="{
        'connected': element.connectionStatus.trim().toLowerCase() === 'connected',
        'disconnected': element.connectionStatus.trim().toLowerCase() === 'disconnected'
      }">

        {{ element.connectionStatus }}</mat-cell>
    </ng-container>

    <!-- plmn_id-->
    <ng-container matColumnDef="plmn_id">
      <mat-header-cell *matHeaderCellDef> Plmn id </mat-header-cell>
      <mat-cell *matCellDef="let element">
        {{ element.globalNbId.plmnId }}
      </mat-cell>
    </ng-container>

    <!-- nb_id  -->
    <ng-container matColumnDef="nbid">
      <mat-header-cell *matHeaderCellDef> Nbid</mat-header-cell>
      <mat-cell *matCellDef="let element">
        {{ element.globalNbId.nbId }}
      </mat-cell>
    </ng-container>

    <!--Inventory_name -->
    <ng-container matColumnDef="Inventory_name">
      <mat-header-cell *matHeaderCellDef> gNodeB Name</mat-header-cell>
      <mat-cell *matCellDef="let element" (click)="onCellClick(element, 'gnbinfo')"
        class="inventory_pointer1">
        {{ element.inventoryName }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="noRecordsFound">
      <mat-footer-cell *matFooterCellDef></mat-footer-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
  </table>
  <!-- sidebar by clicking on inventory names  -->
  <!-- <mat-sidenav-container>
<mat-sidenav>
<rd-side-bar [sidebarData]="selectedInventory" *ngIf="isSidebarOpen" (close)="closeSidebar()"></rd-side-bar>
</mat-sidenav>
</mat-sidenav-container> -->

  <!-- open -side bar  -->
</div>
<div class="sidebar-panel-right" *ngIf="isSidebarOpen">
  <div class="header">
    <h3 class="rd-global-page-title">
      GNB NAME

      <button (click)="refreshPage()">
        <mat-icon>refresh</mat-icon>
      </button>
    </h3>
  </div>
  <div>
    <button class="clear_icon" (click)="closeSidebar()">
      <mat-icon>clear</mat-icon>
    </button>
  </div>
  <div class="catalog__section">
    <table mat-table [dataSource]="dataSource2" matSort class="catalog-table" class="catalog_sect"
      matSortActive="plmn_id" matSortDirection="asc">
      <!-- connection_status  -->
      <ng-container matColumnDef="CCC">
        <mat-header-cell *matHeaderCellDef> CCC </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{ element.CCC }}</mat-cell>
      </ng-container>

      <!-- plmn_id-->
      <ng-container matColumnDef="KPM">
        <mat-header-cell *matHeaderCellDef> KPM </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{ element?.KPM }} </mat-cell>
      </ng-container>

      <!-- nb_id  -->
      <ng-container matColumnDef="RC">
        <mat-header-cell *matHeaderCellDef> RC</mat-header-cell>
        <mat-cell *matCellDef="let element"> {{ element.RC }} </mat-cell>
      </ng-container>

      <!--Inventory_name -->
      <ng-container matColumnDef="Inventory_name">
        <!-- <mat-header-cell *matHeaderCellDef> Inventory name</mat-header-cell> -->
        <!-- <mat-cell *matCellDef="let element" (click)="openSidebar(element)"> {{element.inventoryName}} </mat-cell> -->
      </ng-container>

      <ng-container matColumnDef="noRecordsFound">
        <mat-footer-cell *matFooterCellDef></mat-footer-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns2; sticky: true"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns2"></mat-row>
    </table>
  </div>
</div>