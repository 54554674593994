import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { XappOnboarderService } from '../services/xapp-onboarder/xapp-onboarder.service';
import { ActivatedRoute } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { Observable } from 'rxjs/Observable';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { NotificationService } from '../services/ui/notification.service';
interface KpmDetail {
  ranfuncId: string;
  ranfuncOid: string;
  ranfuncDef: string;
  shortName: string;
  oid: string;
  description: string;
  eventTriggerStyleName: string;
  reportStylistName: string;
  headerFormat: string;
  messageFormat: string;
  ranFuncDescription: string;
  measurement: string;
}

interface RcDetail {
  ranfuncId: string;
  ranfuncOid: string;
  ranfuncDef: string;
  differentKey1: string;
  differentKey2: string;
  anotherDescription: string;
  ranFuncDescription: string;
}

interface CccDetail {
  ranfuncId: string;
  ranfuncOid: string;
  ranfuncDef: string;
  additionalKey1: string;
  additionalKey2: string;
  anotherDescription: string;
  ranFuncDescription: string;
}

interface response {
  inventoryName: string,
  plmnId: string,
  nbId: string,
  connectionStatus: string,
  healthCheckTimestampSent: string
}

export interface PeriodicElement {
  CellID: number;
  Prbusage: string;
  pdcpUL: string;
  pdcpDL: string;
  connMean: string;
  connMax: string;
}

const ELEMENT_DATA: PeriodicElement[] = [
  { CellID: 1, Prbusage: 'Dummy', pdcpUL: "Dummy", pdcpDL: "Dummy", connMean: "Dummy", connMax: 'Dummy' },
  { CellID: 2, Prbusage: 'Dummy', pdcpUL: "Dummy", pdcpDL: "Dummy", connMean: "Dummy", connMax: 'Dummy' },
  { CellID: 3, Prbusage: 'Dummy', pdcpUL: "Dummy", pdcpDL: "Dummy", connMean: "Dummy", connMax: 'Dummy' },
];

@Component({
  selector: 'rd-gnb-information',
  templateUrl: './gnb-information.component.html',
  styleUrls: ['./gnb-information.component.scss']
})
export class GnbInformationComponent implements OnInit {
  gnbInfo$: Observable<any>;
  selectedDetail: string = 'KPM'; // Default selected detail
  details: any = [];
  inventory: any;
  dataSource!: MatTableDataSource<any>;
  data: any = {};
  selectedCategory: string = 'kpm';
  selectedTab: string = 'general';
  gnbInfo: {};
  gnbData: any;
  inventoryName: string;
  connectionStatus: string;
  nbId: string;
  plmnId: string;
  healthCheckTimestampSent: string;
  header: string;
  displayedColumns: string[] = ['Cell-ID', 'Prbusage', 'pdcpUL', 'pdcpDL', 'connMean', 'connMax'];
  dataSource1 = ELEMENT_DATA;
  kpmData: [];
  rcData: [];
  cccData: [];

  constructor(
    private xappOnboarderService: XappOnboarderService,
    private route: ActivatedRoute,
    private changeDetectorRef: ChangeDetectorRef,
    private cdr: ChangeDetectorRef,
    private notificationService: NotificationService,

  ) { }

  ngOnInit(): void {
    // for showing gnbinfo in first container 
    this.route.paramMap.subscribe(params => {
      const id = params.get('id');
      if (id) {
        this.xappOnboarderService.gnbDetails(id).subscribe(
          (response: any) => {
            let data1 = response.split(',');
            this.inventoryName = data1[0].split(':"')[1].replace('"', '');
            this.connectionStatus = data1[3].split(':"')[1].replace('"', '');
            this.nbId = data1[2].split(':"')[1].replace('"', '');
            this.plmnId = data1[1].split(':"')[1].replace('"', '');
            this.healthCheckTimestampSent = data1[4].split(':"')[1].replace('"', '').replace('}', '');
          },
          error => {
            console.error('Error fetching GNB info', error);
          }
        );
      }
    });

    if (this.route.snapshot?.params['id']) {
      this.getGnbInfo(this.route.snapshot?.params['id']);
    }

    this.updateinfo()
    if (this.route.snapshot?.params['id']) {
      this.fetchDetails(this.route.snapshot?.params['id']);
    }
  }

  updateinfo(): void {
    this.gnbInfo = this.xappOnboarderService.getInfoData
  }

  selectCategory(category: string): void {
    this.selectedCategory = category;
    this.selectedTab = 'general'; // Default to general tab when category is changed
  }

  selectTab(tab: string): void {
    this.selectedTab = tab;
  }

  getGnbInfo(id: string) {
    // this.xappOnboarderService.gnbDetails(id).subscribe(
    //   response => {
    //     this.data = response[0];
    //     console.log(response,"gnbinforesponse")
    //     console.log(typeof response,"type of response")
    //   },
    //   error => {
    //     console.error('There was an error!', error);
    //   }
    // );
    // this.gnbInfo$=this.xappOnboarderService.gnbDetails(id)
    //   this.xappOnboarderService.gnbDetails(id).subscribe(data =>{
    //     this.gnbInfo = data;
    //     console.log(this.gnbInfo);
    //     console.log(typeof this.gnbInfo,"type of api") // Verify data is fetched
    //     this.cdr.detectChanges();
    // })
  }

  fetchDetails(id: string) {
    this.xappOnboarderService.getKpmData(id).subscribe({
      next: (data: any) => {
        let apiResData = JSON.parse(data);
        let apiData = apiResData["measurement"];

        if (apiData["E2SM-KPM"]) {
          this.kpmData = apiData["E2SM-KPM"];
          console.log(this.kpmData, "kpm data");
        }

        if (apiData["E2SM-RC"]) {
          this.rcData = apiData["E2SM-RC"];
          console.log(this.rcData, "Rc data");
        }

        if (apiData["E2SM-CCC"]) {
          this.cccData = apiData["E2SM-CCC"];
          console.log(this.cccData, "CCC data");
        }
      }
    });
  }

  updateToggleValue(data) {
    if (data) {
      let payload = data.map(item => ({
        "measId": item.measId,
        "state": item.active
      }));

      if (payload) {
        this.xappOnboarderService.updateToggleButton(payload)
          .subscribe(
            (response: HttpResponse<Object>) => {
              this.xappOnboarderService.Refreshrequired.subscribe(Response => {
              })
              this.notificationService.success('Updated sucessfully!');
            },
            ((her: HttpErrorResponse) => {
              this.notificationService.warn("Update failed");
              let msg = her.message;
              if (her.error && her.error.message) {
                msg = her.error.message;
              }
            })
          );
      }
    }
  }

  hasData(detail: string): boolean {
    if (this.inventory && this.inventory[detail.toLowerCase()]) {
      const detailData = this.inventory[detail.toLowerCase()];
      return detailData.ranFuncID !== 0;
    }
    return false;
  }

  objectKeys(obj: any): string[] {
    return Object.keys(obj);
  }

  onSelect(detail: string) {
    console.log('Selected detail:', detail);
    this.selectedDetail = detail;
    console.log('Current selected details:', this.details[this.selectedDetail]); // Debug statement
    // this.fetchDetails(this.route.snapshot?.params['id'], detail); 
  }

}


